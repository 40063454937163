import React, { useState, Fragment } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import preloader from '../images/preloader.svg'
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Features from "./features";
// import Adsense from "./adsense"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faVolumeMute, faExclamationTriangle, faDownload, faMusic, faClipboard } from '@fortawesome/free-solid-svg-icons'
const axios = require('axios').default;

const RedditDownloader = ({ title, description }) => {
    const { t } = useTranslation();
    const [inputUrl, setInputUrl] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [responseData, setResponseData] = useState({});
    const [videosData, setVideosData] = useState({});
    const [audiosData, setAudiosData] = useState({});
    const [tableCollaspe, setTableCollaspe] = useState("videos-collapse");
    const [tableCollaspeText, setTableCollaspeText] = useState("Show More");
    const [showVideosSection, setShowVideosSection] = useState(false);
    const [showNoVideoError, setShowNoVideoError] = useState(false);

    const handleInputChange = (e) => {
        const url = e.target.value;
        setInputUrl(url);
        getFile(url);
    }

    const downloadButton = () => {
        getFile(inputUrl);
    }

    const getFile = (url) => {
        setShowVideosSection(false);
        setShowNoVideoError(false);
        if (url !== "") {
            setShowLoader(true);
            url = url.split("?")[0] + ".json";
            axios.get(url)
                .then(function (response) {
                    setShowLoader(false);
                    const data = response.data[0]?.data?.children[0]?.data;
                    let duration = 0
                    if (data?.media?.reddit_video?.duration) {
                        let duration_new = data?.media?.reddit_video?.duration;
                        duration = Math.floor(duration_new/60) + ":" + duration_new%60
                    }
                    setResponseData({
                        thumb: data.thumbnail,
                        title: data.title,
                        duration: duration
                    });

                    var videos = [];
                    var audios = [];
                    let id = data.url.slice(18);

                    audios.push({
                        url: `https://v.redd.it/${id}/DASH_audio.mp4`,
                        ext: "mp3",
                        quality: "128"
                    });

                    videos.push({
                        url: `https://sd.redditsave.com/download.php?permalink=${url}&video_url=https://v.redd.it/${id}/DASH_720.mp4&audio_url=https://v.redd.it/${id}/DASH_audio.mp4`,
                        ext: "mp4",
                        quality: "720p"
                    });

                    ["480", "360", "240", "220"].map((quality) => {
                        videos.push({
                            url: `https://sd.redditsave.com/download-sd.php?permalink=${url}&video_url=https://v.redd.it/${id}/DASH_${quality}.mp4&audio_url=https://v.redd.it/${id}/DASH_audio.mp4`,
                            ext: "mp4",
                            quality: quality + "p"
                        });
                        return quality;
                    });
                    setVideosData(videos);
                    setAudiosData(audios);
                    setShowVideosSection(true);
                })
                .catch(function (error) {
                    setShowLoader(false);
                    setShowNoVideoError(true);
                });
        }
    }

    const showHideVideos = () => {
        if (tableCollaspe === "") {
            setTableCollaspe("videos-collapse");
            setTableCollaspeText("Show More");
        }
        else {
            setTableCollaspe("");
            setTableCollaspeText("Show Less");
        }
    }

    const downloadVideo = (url) => {
        window.open(url, "_blank");
    }

    const pasteLink = () => {
        navigator.clipboard.readText()
            .then(text => {
                setInputUrl(text);
                getFile(text);
            })
            .catch(err => { });
    }

    return (
        <>
            <Row>
                <Col className="download-section text-center pt-5 pb-5">
                    <h1 className="mt-3">{title}</h1>
                    <p className="fs-5">{description}</p>
                    <Row className="justify-content-center">
                        <Col xs lg="5" md="9" className="p-3">
                            <InputGroup>
                                <Form.Control
                                    size="lg"
                                    placeholder={t("Enter video link here")}
                                    aria-label={t("Enter video link here")}
                                    aria-describedby="downloader"
                                    value={inputUrl}
                                    onChange={handleInputChange}
                                />
                                <div className="paste-link" onClick={pasteLink}><FontAwesomeIcon icon={faClipboard} /></div>
                                <Button variant="danger" id="button-download" onClick={downloadButton}>
                                    {t("Download")} &#8594;
                                </Button>
                            </InputGroup>
                            <p className="legal mt-1 mb-0"><strong>{t("Legal Disclaimer")}:</strong> {t("You must be the owner of the video's rights or have permission from its owner in order to use this tool")}.</p>
                            {showLoader &&
                                (<img src={preloader} alt="preloader"></img>)
                            }
                        </Col>
                    </Row>
                    {showNoVideoError && (
                        <Row className="justify-content-center mt-3">
                            <Col xs lg="8" md="9" className="text-yellow">
                                <FontAwesomeIcon style={{ fontSize: "60px" }} icon={faExclamationTriangle} />
                                <p className="mb-1">{t("No download link found")}!!</p>
                                <p>{t("Please make sure the video URL is correct and video or the account of the video is not private")}!</p>
                            </Col>
                        </Row>
                    )}
                    {/* <Row className="justify-content-center mt-2">
                        <Col className="text-center">
                            <Adsense slot="4259312234"/>
                        </Col>
                    </Row> */}
                    {showVideosSection && (
                        <Row className="justify-content-center mt-3">
                            <Col xs lg="8" md="9">
                                <Card className="shadow videos-card">
                                    <Card.Body className="download-section">
                                        <Row className="text-start">
                                            <Col lg="5" md="5" xs="12" className="order-last order-md-first">
                                                {responseData?.thumb && (
                                                    <>
                                                        <img src={responseData?.thumb} className="w-100 thumb-img" alt="thumbnail" />
                                                        <p className="my-2 text-center"><strong>{responseData?.title}</strong></p>
                                                        {responseData?.duration && (
                                                            <p>{t("Duration")}: {responseData?.duration}</p>
                                                        )}
                                                    </>
                                                )}
                                            </Col>
                                            <Col>
                                                <Tabs
                                                    defaultActiveKey="videos"
                                                    id="download-files"
                                                    fill
                                                >
                                                    <Tab eventKey="videos" title={<Fragment><FontAwesomeIcon icon={faPlayCircle} /> {t("Videos")}</Fragment>}>
                                                        <Table bordered style={{ color: "white" }} className={`videos-table ${tableCollaspe}`}>
                                                            <tbody>
                                                                {videosData?.map((video) => (
                                                                    <tr key={video?.quality + '-' + video?.ext}>
                                                                        <td>
                                                                            <span className="float-start pt-1-5"><FontAwesomeIcon className="text-yellow" icon={faPlayCircle} /> <strong>{video?.quality}</strong>.{video?.ext}</span>
                                                                            <span className="float-end"><Button variant="danger" onClick={() => downloadVideo(video?.url)} type="button"><FontAwesomeIcon icon={faDownload} /> {t("Download")}</Button></span>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                {videosData && videosData?.length > 3 && (
                                                                    <tr style={{ display: "table-row", cursor: "pointer" }}>
                                                                        <td className="text-center" style={{ cursor: "pointer" }} onClick={showHideVideos}>{tableCollaspeText}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </Tab>
                                                    {audiosData?.length > 0 && (
                                                        <Tab eventKey="audios" title={<Fragment><FontAwesomeIcon icon={faMusic} /> {t("Audios")}</Fragment>}>
                                                            <Table bordered style={{ color: "white" }} className={`videos-table ${tableCollaspe}`}>
                                                                <tbody>
                                                                    {audiosData?.map((video) => (
                                                                        <tr key={video?.quality + '-' + video?.ext}>
                                                                            <td>
                                                                                <span className="float-start pt-1-5"><FontAwesomeIcon className="text-yellow" icon={faMusic} /> <strong>{video?.quality}</strong>.{video?.ext} {video?.attr?.class === "no-audio" && (<span className="text-yellow"><FontAwesomeIcon icon={faVolumeMute} /></span>)}</span>
                                                                                <span className="float-end"><Button variant="danger" onClick={() => downloadVideo(video?.url)} type="button"><FontAwesomeIcon icon={faDownload} /> {t("Download")}</Button></span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    {audiosData && audiosData?.length > 3 && (
                                                                        <tr style={{ display: "table-row", cursor: "pointer" }}>
                                                                            <td className="text-center" style={{ cursor: "pointer" }} onClick={showHideVideos}>{tableCollaspeText}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </Tab>
                                                    )}
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Features title={title} />
        </>
    );
}

export default RedditDownloader
